import { string } from "prop-types";
import { Navigate } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  TAdmin,
  TAdminInitialStateType,
  TApproveReportInitialStateType,
  TCheckUserAdminStatusInitialStateType,
  TSaveNotesInitialStateType,
  TKitStatusCount,
  KitStatusCount,
  TNutritionistInitialStateType,
  Nutritionist,
  TLabInfoInitialStateType,
  LabInfo,
  UpdateHorse,
  TUpdateHorseInitialStateType,
  TUpdateSampleByKitInitialStateType,
  UpdateSampleByKitID,
  TUpdateReportSampleResponse,
  UpdateLabInfo,
  TUpdateLabInfoInitialStateType,
  TUpdateLabInfoResponse,
  TRequestNewSampleInitialStateType,
  TRequestNewSampleEmail,
  TRequestNewSampleEmailResponse,
} from "../../../../models/reports/admin/ReportsAdminModel";
import ReportsAdminService from "../../../../services/reports/admin/ReportsAdminService";
import reducer from "../ReportsSlice";

const initialState: TCheckUserAdminStatusInitialStateType = {
  CheckUserAdminStatusData: [],
  CheckUserAdminStatusLoading: true,
  CheckUserAdminStatusError: undefined,
};

export const retrieveCheckUserAdminStatus = createAsyncThunk(
  "home/CheckUserAdminStatus/retrieve",
  async (customerID: string, { rejectWithValue }) => {
    try {
      const response = await ReportsAdminService.getCheckUserAdminStatus(
        customerID
      );

      return [response.data];
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (error.response.status) {
        window.location.href = "/page-not-found";
      }
      if (!error.response) {
        throw error;
      }

      return rejectWithValue([error.response.data]);
    }
  }
);

const CheckUserAdminStatusSlice = createSlice({
  name: "CheckUserAdminStatus",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveCheckUserAdminStatus.pending, (state) => {
        state.CheckUserAdminStatusLoading = true;
      })
      .addCase(retrieveCheckUserAdminStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.CheckUserAdminStatusLoading = false;
          state.CheckUserAdminStatusData = action.payload as boolean[];
        }
      })
      .addCase(retrieveCheckUserAdminStatus.rejected, (state, action) => {
        state.CheckUserAdminStatusLoading = false;
      });
  },
});

export const { reducer: CheckUserAdminStatusReducer } =
  CheckUserAdminStatusSlice;

const AdmininitialState: TAdminInitialStateType = {
  AdminData: [],
  AdminLoading: true,
  AdminError: undefined,
};

export type TAdminTable = {
  customerID: string;
  pageNumber: number;
  pageSize: number;
  searchText: string;
  status: string;
  showCompleted: boolean;
  nutritionist: string;
};

export const retrieveAdmin = createAsyncThunk(
  "home/Admin/retrieve",
  async (AdminTable: TAdminTable, { rejectWithValue }) => {
    try {
      // Encode parameters that might contain special characters
      const encodedSearchText = encodeURIComponent(AdminTable.searchText);
      // Fetch data with encoded parameters
      const response = await ReportsAdminService.getAdmin(
        AdminTable.customerID,
        AdminTable.pageNumber,
        AdminTable.pageSize,
        encodedSearchText,
        AdminTable.status,
        AdminTable.showCompleted,
        AdminTable.nutritionist
      );
      return [response.data];
    } catch (error) {
      // Handle error by rejecting with value
      return rejectWithValue("Failed to retrieve admin data");
    }
  }
);

const AdminSlice = createSlice({
  name: "Admin",
  initialState: AdmininitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveAdmin.pending, (state) => {
        state.AdminLoading = true;
      })
      .addCase(retrieveAdmin.fulfilled, (state, action) => {
        if (action.payload) {
          state.AdminLoading = false;
          state.AdminData = action.payload as TAdmin[];
        }
      })
      .addCase(retrieveAdmin.rejected, (state, action) => {
        state.AdminLoading = false;
      });
  },
});

export const { reducer: AdminReducer } = AdminSlice;

// Approve Report slice
const ApproveReportInitialState: TApproveReportInitialStateType = {
  ApproveReportData: [],
  ApproveReportLoading: true,
  ApproveReportError: undefined,
};

export const retrieveApproveReport = createAsyncThunk(
  "home/ApproveReport/retrieve",
  async (kitID: string, { rejectWithValue }) => {
    try {
      const response = await ReportsAdminService.getApproveReport(kitID);

      return [response.data];
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (error.response.status) {
        window.location.href = "/page-not-found";
      }
      if (!error.response) {
        throw error;
      }

      return rejectWithValue([error.response.data]);
    }
  }
);

const ApproveReportSlice = createSlice({
  name: "ApproveReport",
  initialState: ApproveReportInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveApproveReport.pending, (state) => {
        state.ApproveReportLoading = true;
      })
      .addCase(retrieveApproveReport.fulfilled, (state, action) => {
        if (action.payload) {
          state.ApproveReportLoading = false;
          state.ApproveReportData = action.payload as boolean[];
        }
      })
      .addCase(retrieveApproveReport.rejected, (state, action) => {
        state.ApproveReportLoading = false;
      });
  },
});

export const { reducer: ApproveReportReducer } = ApproveReportSlice;

// Note Report slice
const SaveNotesInitialState: TSaveNotesInitialStateType = {
  SaveNotesData: [],
  SaveNotesLoading: true,
  SaveNotesError: undefined,
};

export type TSaveNote = {
  kitID: string;
  Notes: string;
};

export const retrieveSaveNotes = createAsyncThunk(
  "home/SaveNotes/retrieve",
  async (saveNotes: TSaveNote, { rejectWithValue }) => {
    try {
      const response = await ReportsAdminService.getSaveNotes(
        saveNotes.kitID,
        saveNotes.Notes
      );

      return [response.data];
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (error.response.status) {
        window.location.href = "/page-not-found";
      }
      if (!error.response) {
        throw error;
      }

      return rejectWithValue([error.response.data]);
    }
  }
);

const SaveNotesSlice = createSlice({
  name: "SaveNotes",
  initialState: SaveNotesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveSaveNotes.pending, (state) => {
        state.SaveNotesLoading = true;
      })
      .addCase(retrieveSaveNotes.fulfilled, (state, action) => {
        if (action.payload) {
          state.SaveNotesLoading = false;
          state.SaveNotesData = action.payload as boolean[];
        }
      })
      .addCase(retrieveSaveNotes.rejected, (state, action) => {
        state.SaveNotesLoading = false;
      });
  },
});

export const { reducer: SaveNotesReducer } = SaveNotesSlice;

const KitStatusCountinitialState: TKitStatusCount = {
  KitStatusCountData: {} as KitStatusCount,
  KitStatusCountLoading: true,
  KitStatusCountError: undefined,
};

export const retrieveKitStatusCount = createAsyncThunk(
  "home/Admin/Kit-Status-Count",
  async () => {
    const response = await ReportsAdminService.getKitStatusCount();
    return response.data;
  }
);

const StatusCountSlice = createSlice({
  name: "KitStatusCount",
  initialState: KitStatusCountinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveKitStatusCount.pending, (state) => {
        state.KitStatusCountLoading = true;
      })
      .addCase(retrieveKitStatusCount.fulfilled, (state, action) => {
        if (action.payload) {
          state.KitStatusCountLoading = false;
          state.KitStatusCountData = action.payload;
        }
      })
      .addCase(retrieveKitStatusCount.rejected, (state, action) => {
        state.KitStatusCountLoading = false;
      });
  },
});
export const { reducer: KitStatusCountReducer } = StatusCountSlice;

const NutritionistInitialStateType: TNutritionistInitialStateType = {
  NutritionistData: [],
  NutritionistLoading: true,
  NutritionistError: undefined,
};

export type TNutritionist = {
  id: string;
  name: string;
};
export const retrieveNutritionist = createAsyncThunk(
  "Admin/Nutritionist",
  async () => {
    const response = await ReportsAdminService.getNutritionist();
    return response.data;
  }
);
const NutritionistSlice = createSlice({
  name: "Nutritionist",
  initialState: NutritionistInitialStateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveNutritionist.pending, (state) => {
        state.NutritionistLoading = true;
      })
      .addCase(retrieveNutritionist.fulfilled, (state, action) => {
        if (action.payload) {
          state.NutritionistLoading = false;
          state.NutritionistData = action.payload as Nutritionist[];
        }
      })
      .addCase(retrieveNutritionist.rejected, (state, action) => {
        state.NutritionistLoading = false;
      });
  },
});
export const { reducer: NutritionistReducer } = NutritionistSlice;

const LabInfoinitialState: TLabInfoInitialStateType = {
  LabInfoData: null,
  LabInfoLoading: true,
  LabInfoError: undefined,
};

export const retrieveLabInfo = createAsyncThunk(
  "Admin/LabInfo",
  async (kitID: string, { rejectWithValue }) => {
    const response = await ReportsAdminService.getLabInfoByKitID(kitID);
    return response.data;
  }
);

const labInfoSlice = createSlice({
  name: "LabInfo",
  initialState: LabInfoinitialState,
  reducers: {
    resetModalState: (state) => {
      state.LabInfoData = null;
      state.LabInfoLoading = false;
      state.LabInfoError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveLabInfo.pending, (state) => {
        state.LabInfoLoading = true;
      })
      .addCase(retrieveLabInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.LabInfoLoading = false;
          state.LabInfoData = action.payload as LabInfo;
        }
      })
      .addCase(retrieveLabInfo.rejected, (state, action) => {
        state.LabInfoLoading = false;
      });
  },
});
export const { resetModalState } = labInfoSlice.actions;
export const { reducer: LabInfoReducer } = labInfoSlice;

const updateHorseinitialState: TUpdateHorseInitialStateType = {
  UpdateHorseData: null,
  UpdateHorseLoading: true,
  UpdateHorseError: undefined,
};

export const UpdateMyHorse = createAsyncThunk(
  "admin/update-horse",
  async (horseInfo: UpdateHorse, { rejectWithValue }) => {
    try {
      const response = await ReportsAdminService.UpdateHorseAB(horseInfo);
      const { data, status } = response;
      return [{ data, status }];
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (!error.response) {
        throw error;
      }
      return rejectWithValue([error.response.data]);
    }
  }
);
const UpdateHorseSlice = createSlice({
  name: "admin/update-horse",
  initialState: updateHorseinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateMyHorse.pending, (state) => {
        state.UpdateHorseLoading = true;
      })
      .addCase(UpdateMyHorse.fulfilled, (state, action) => {
        if (action.payload) {
          const transformedData = action.payload.map(
            (item: { data: any; status: number }) => ({
              ID: item.data.ID,
              HorseBreed: item.data.HorseBreed,
              HorseAge: item.data.HorseAge,
            })
          ) as UpdateHorse[]; // Assuming the API returns an array

          state.UpdateHorseLoading = false;
          state.UpdateHorseData = transformedData[0] || null; // Use the first item if it's an array
        }
      })
      .addCase(UpdateMyHorse.rejected, (state, action) => {
        state.UpdateHorseLoading = false;
      });
  },
});

export const { reducer: UpdateHorseReducer } = UpdateHorseSlice;
const UpdateSampleInitialState: TUpdateSampleByKitInitialStateType = {
  UpdateSampleByKitData: null,
  UpdateSampleByKitLoading: true,
  UpdateSampleByKitError: undefined,
};

// export const UpdateSampleByKit=createAsyncThunk(
//   "admin/UpdateSampleByKit",
//   async(sample:UpdateSampleByKit, { rejectWithValue }) => {

//   })

export const UpdateSampleByKit = createAsyncThunk(
  "admin/UpdateSampleByKit",
  async (sample: string, { rejectWithValue }) => {
    try {
      const response = await ReportsAdminService.UpdateReportSampleByKit(
        sample
      );
      const { data, status } = response;
      return { data, status };
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (!error.response) {
        throw error;
      }
      return rejectWithValue([error.response.data]);
    }
  }
);

const UpdateReportSampleByKitSlice = createSlice({
  name: "admin/updatesamplebykit",
  initialState: UpdateSampleInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateSampleByKit.pending, (state) => {
        state.UpdateSampleByKitLoading = true;
        state.UpdateSampleByKitError = undefined;
      })
      .addCase(UpdateSampleByKit.fulfilled, (state, action) => {
        state.UpdateSampleByKitLoading = false;
        state.UpdateSampleByKitData =
          action.payload as TUpdateReportSampleResponse;
      })
      .addCase(UpdateSampleByKit.rejected, (state, action) => {
        state.UpdateSampleByKitLoading = false;
        state.UpdateSampleByKitError = undefined;
        state.UpdateSampleByKitData =
          action.payload as TUpdateReportSampleResponse;
      });
  },
});
export const { reducer: UpdateReportSampleReducer } =
  UpdateReportSampleByKitSlice;

// export type TLabInfo = {
//   kitID: string;
//   notes: string;
//   nutritionistID:string;
//   timeonReport:string;
//   nutritionistAssinedDate:string
// };
const UpdateLabInfoInitialState: TUpdateLabInfoInitialStateType = {
  UpdateLabInfoData: null,
  UpdateLabInfoLoading: true,
  UpdateLabInfoError: undefined,
};
export const UpdateLabInfoByKit = createAsyncThunk(
  "admin/UpdateLabInfoByKit",
  async (labInfo: UpdateLabInfo, { rejectWithValue }) => {
    try {
      const response = await ReportsAdminService.UpdateLabInfo(labInfo);
      const { data, status } = response;
      return { data, status };
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (!error.response) {
        throw error;
      }
      return rejectWithValue([error.response.data]);
    }
  }
);
const UpdateLabInfoSlice = createSlice({
  name: "admin/udpatelab",
  initialState: UpdateLabInfoInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateLabInfoByKit.pending, (state) => {
        state.UpdateLabInfoLoading = true;
      })
      .addCase(UpdateLabInfoByKit.fulfilled, (state, action) => {
        state.UpdateLabInfoLoading = false;
        state.UpdateLabInfoData = action.payload as TUpdateLabInfoResponse;
      })
      .addCase(UpdateLabInfoByKit.rejected, (state, action) => {
        state.UpdateLabInfoLoading = false;
      });
  },
});

export const { reducer: UpdateLabInfoReducer } = UpdateLabInfoSlice;

const RequestNewSampleInitialState: TRequestNewSampleInitialStateType = {
  RequestNewSampleData: null,
  RequestNewSampleError: undefined,
  RequestNewSampleLoading: true,
};
export const RequestNewSample = createAsyncThunk(
  "admin/requestnewsample",
  async (email: TRequestNewSampleEmail, { rejectWithValue }) => {
    try {
      const response = await ReportsAdminService.RequestNewSample(email);
      const { data, status } = response;
      return { data, status };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue([error.response.data]);
    }
  }
);

const RequestNewSampleSlice=createSlice({
  name:"admin/requestnewsample",
  initialState:RequestNewSampleInitialState,
  reducers:{},
  extraReducers:(builder)=>{
    builder
    .addCase(RequestNewSample.pending,(state)=>{
      state.RequestNewSampleLoading=true;
    })
    .addCase(RequestNewSample.fulfilled,(state,action)=>{
      state.RequestNewSampleLoading=false;
      state.RequestNewSampleData=action.payload as TRequestNewSampleEmailResponse
    })
    .addCase(RequestNewSample.rejected,(state,action)=>{
      state.RequestNewSampleLoading=false;
    })
  }
})
export const { reducer: RequestNewSampleReducer } = RequestNewSampleSlice;