import { HttpStatusCode } from "axios";
import http from "../../../http-common";
import {
  TRequestNewSampleEmail,
  UpdateHorse,
  UpdateLabInfo,
  UpdateSampleByKitID,
} from "../../../models/reports/admin/ReportsAdminModel";

export class ReportsAdminService {
  getCheckUserAdminStatus(customerID: string) {
    return http.get(`/Admin/CheckUserAdminStatus?customerID=${customerID}`);
  }
  getAdmin(
    customerID: string,
    pageNumber: number,
    pageSize: number,
    searchText: string,
    status: string,
    showCompleted: boolean,
    nutritionist: string
  ) {
    return http.get(
      `/Admin/Admin?customerID=${customerID}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}&status=${status}&showCompletedReports=${showCompleted}&nutritionist=${nutritionist}`
    );
  }
  getApproveReport(kitID: string) {
    return http.get(`/Report/ApproveReport?kitID=${kitID}`);
  }
  getSaveNotes(kitID: string, notes: string) {
    return http.patch(`/Report/SaveNotes?kitID=${kitID}`, { notes: notes });
  }
  getKitStatusCount() {
    return http.get("/Admin/kit-status-counts");
  }
  getNutritionist() {
    return http.get(`/Admin/nutritionist`);
  }
  getLabInfoByKitID(kitID: string) {
    return http.get(`/Admin/labinfo?kitID=${kitID}`);
  }
  UpdateHorseAB(horseInfo: UpdateHorse) {
    return http.put(
      `/Admin/UpdateHorse?ID=${horseInfo.ID}&horseBreed=${horseInfo.HorseBreed}&otherBreed=${horseInfo.OtherBreed}&horseAge=${horseInfo.HorseAge}`,
      horseInfo,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  UpdateReportSampleByKit(sample: string) {
    return http.post(`/Admin/UpdateReport`, sample, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  UpdateLabInfo(labInfo: UpdateLabInfo) {
    return http.post(`/Admin/UpdateLabInfo`, labInfo, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  RequestNewSample(email: TRequestNewSampleEmail) {
    return http.post(`/Admin//RequestNewSample`, email, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new ReportsAdminService();
